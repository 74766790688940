import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import { Primary, Secondary } from '../../components/Section'
import HomeHero from './HomeHero'
import { HrefButtonInverse } from '../Button'
import SaveMoneyHero from './SaveMoneyHero'
import { BackgroundSectionStyle } from '../Section/backgroundSection.css.js'
import AsideImage from '../../components/AsideImage'
import ImgAsideLightning from '../../images/aside-lightning.jpg'
import ImgAsideSwimming from '../../images/aside-swimming.jpg'

const StyledHomeHero = styled(HomeHero)`
  ${BackgroundSectionStyle};
  padding-top: 10rem;
  min-height: ${props => props.backgroundHeight}px;
  text-align: ${props => (props.centered ? 'center' : 'initial')};
  h1,
  h2,
  h3 {
    color: ${props => props.theme.white};
  }
`
const StyledSaveMoneyHero = styled(SaveMoneyHero)`
  ${BackgroundSectionStyle};
  padding-top: 10rem;
  min-height: ${props => props.backgroundHeight}px;
  text-align: ${props => (props.centered ? 'center' : 'initial')};
  h1,
  h2,
  h3 {
    color: ${props => props.theme.white};
  }
  p {
    background-color: rgba(0, 0, 0, 0.5);
  }
`
const Home = () => (
  <section>
    <StyledHomeHero backgroundHeight={550} centered>
      <Row center="md">
        <Col xs={12} md={8}>
          <h1>MJMD MEDIATION, COACHING, & SPORTS MANAGEMENT</h1>
        </Col>
      </Row>
    </StyledHomeHero>
    <Primary>
      <Row>
        <Col xs={12} md={8}>
          <h2>Contact us First for a Free Consultation!</h2>
          <p>
            Don’t give up! Don’t let the business of divorce attempt to destroy
            the person who you are! Save your money! Stay out the court room!
          </p>
          <p>
            Call us if your are confused, angry, hurt, worried and need closure
            with your divorce. We will call you back within 24 hours, usually
            sooner and we can assist you in your journey of divorce and
            mediation. Especially when you know that you are targeted, and you
            realize that others want the money that you worked extremely hard
            for you need to call us. Wether you are in a high conflict divorce,
            dealing with alimony, spousal support or a gay and lesbian divorce
            call us.
          </p>
          <p>
            Our Mediation and Divorce Coaching Corporation focus’ on keeping
            highly targeted professionals, such as doctors, health care
            professionals, professional athletes, CEO’s and others who have
            worked hard for their earned income out of the court room. Let our
            coaches guide you through the journey and challenges of divorce.
            Don’t do it by yourself. Save your money!
          </p>
        </Col>
        <Col xs={12} md={4}>
          <AsideImage imgSrc={ImgAsideLightning} imgAlt="Hollywood Sign" />
        </Col>
      </Row>
    </Primary>
    <StyledSaveMoneyHero backgroundHeight={550} centered>
      <Row center="xs">
        <Col xs={8}>
          <h1>Staying out of the court room saves you money!</h1>
          <p>
            Don’t act alone. Mediation, divorce coaching or coparenting coaching
            could be the key to your savings. Also, save and improve your
            personal health and well-being from being destroyed by the stress of
            divorce. We will assist you in making your divorce as smooth as
            possible. Let’s stay focused and keep money in your pocket.
          </p>
          <p>Let’s get it done.</p>
        </Col>
      </Row>
    </StyledSaveMoneyHero>
    <Secondary centered>
      <Row>
        <Col xs={12}>
          <h3>Contact Us</h3>
          <HrefButtonInverse href="mailto:drmichaelj@mjmdcoaching.com">
            Email Us
          </HrefButtonInverse>
        </Col>
      </Row>
    </Secondary>
  </section>
)

export default Home
