import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledImage = styled.img`
  margin-top: 3.375rem;
`

const AsideImage = ({ imgSrc, imgAlt }) => (
  <StyledImage src={imgSrc} alt={imgAlt} />
)

AsideImage.propTypes = {
  imgSrc: PropTypes.node,
  imgAlt: PropTypes.string,
}

export default AsideImage
